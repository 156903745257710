<template>
  <div>
    <modificar-reactivo
      v-if="modificarReactivo"
      :idPagina="reactivoLocal.pagina"
      :reactivo="reactivoLocal"
      @cancelar="modificarReactivo = false"
      @reactivoCreado="reactivoCreado"
    ></modificar-reactivo>
    <v-card v-else flat>
      <v-toolbar dense flat color="#fff">
        <v-toolbar-title style="width: 100%">
          <div
            class="d-flex justify-space-between align-center"
            style="width: 100%"
          >
            <div>
              <v-chip small color="secondary" dark>{{
                Number(index) + 1
              }}</v-chip>
              <v-chip v-if="reactivo.tipo === 'opciones'" small class="ml-1"
                >Selección {{ reactivoLocal.seleccion }}</v-chip
              >
            </div>
            <div v-if="permisoEscritura" class="d-flex">
              <v-tooltip
                v-if="reactivo.tipo === 'opciones'"
                color="secondary"
                left
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :color="reactivo.respuestasRandom ? 'success' : 'primary'"
                    :loading="loading"
                    :disabled="loading"
                    small
                    elevation="0"
                    tile
                    :outlined="!reactivo.respuestasRandom"
                    @click="modificarRespuestasRandom()"
                  >
                    <v-icon
                      x-small
                      :color="reactivo.respuestasRandom ? null : 'primary'"
                      >fa-random</v-icon
                    >
                  </v-btn>
                </template>
                <span>Respuestas aleatorias</span>
              </v-tooltip>
              <v-tooltip color="secondary" bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :disabled="loading"
                    color="#eceff1"
                    small
                    light
                    tile
                    elevation="0"
                    @click="mostrarModificarSolucion = true"
                  >
                    <v-icon color="primary" small>mdi-file-check</v-icon>
                  </v-btn>
                </template>
                <span>Editar solución</span>
              </v-tooltip>
              <v-tooltip color="secondary" bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :disabled="loading"
                    color="#eceff1"
                    small
                    light
                    tile
                    elevation="0"
                    @click="modificarReactivo = true"
                  >
                    <v-icon color="primary" small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar reactivo</span>
              </v-tooltip>
            </div>
          </div>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div v-html="reactivoLocal.texto" class="ck-content"></div>
        <v-text-field
          v-if="reactivoLocal.tipo === 'abierta'"
          filled
          readonly
          label="Respuesta"
        ></v-text-field>
        <v-radio-group multiple v-model="respuestasCorrectas" class="mt-0">
          <v-radio
            v-for="respuesta in reactivoLocal.respuestas"
            :key="respuesta._id"
            :color="respuesta.correcta ? 'success' : null"
            :label="respuesta.texto"
            :value="respuesta._id"
            on-icon="mdi-check"
            readonly
          ></v-radio>
        </v-radio-group>

        <div>
          <v-btn
            color="primary"
            text
            x-small
            @click="mostrarSolucion = !mostrarSolucion"
          >
            <v-icon small class="mr-2">{{
              mostrarSolucion ? "mdi-chevron-down" : "mdi-chevron-right"
            }}</v-icon>
            <span>{{ mostrarSolucion ? "Cerrar" : "Mostrar" }} solución</span>
          </v-btn>
          <div
            v-if="mostrarSolucion && reactivoLocal.solucion"
            v-html="reactivoLocal.solucion"
            class="ck-content py-2 px-4"
          ></div>
          <span
            v-else-if="mostrarSolucion && !reactivoLocal.solucion"
            class="font-italic text-caption"
            >Sin solución agregada.</span
          >
        </div>
      </v-card-text>

      <modificar-solucion
        v-if="mostrarModificarSolucion"
        :mostrar="mostrarModificarSolucion"
        :idReactivo="reactivo._id"
        :idPagina="reactivo.pagina"
        :solucion="reactivoLocal.solucion"
        @cancelar="mostrarModificarSolucion = false"
        @solucionModificada="solucionModificada"
      ></modificar-solucion>
    </v-card>
  </div>
</template>

<script>
import { modificarReactivo } from "./examenReactivos.service";

export default {
  props: {
    reactivo: { type: Object, required: true },
    index: { type: [String, Number], default: 0 },
    permisoEscritura: { type: Boolean, default: false },
  },

  components: {
    "modificar-solucion": () => import("./modificarSolucionReactivo.vue"),
    "modificar-reactivo": () => import("./crearReactivoExamen.vue"),
  },

  data() {
    return {
      loading: false,
      reactivoLocal: this.reactivo,
      mostrarModificarSolucion: false,
      modificarReactivo: false,
      mostrarSolucion: false,
    };
  },

  computed: {
    respuestasCorrectas() {
      return this.reactivoLocal.respuestas
        .filter((e) => e.correcta)
        .map((e) => e._id);
    },
  },

  watch: {
    reactivo(value) {
      this.reactivoLocal = value;
    },
  },

  methods: {
    async modificarRespuestasRandom() {
      this.loading = true;

      try {
        const { idExamen, idSeccion } = this.$route.params;
        const data = {
          respuestasRandom: !this.reactivo.respuestasRandom,
        };

        const serverResponse = await modificarReactivo({
          idExamen,
          idSeccion,
          idPagina: this.reactivoLocal.pagina,
          idReactivo: this.reactivoLocal._id,
          data,
        });

        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else
          this.reactivoLocal.respuestasRandom =
            serverResponse.reactivo.respuestasRandom;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    reactivoCreado(reactivo, cerrarModificacion = true) {
      const camposModificados = Object.entries(reactivo).map((e) => e[0]);
      camposModificados.forEach((campo) => {
        this.reactivoLocal[campo] = reactivo[campo];
      });
      if (cerrarModificacion) this.modificarReactivo = false;
    },

    solucionModificada(nuevaSolucion) {
      this.reactivoLocal.solucion = nuevaSolucion;
      this.mostrarModificarSolucion = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../classes/ck-content.css";
</style>
